
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { getRankList, setRank } from "@/api/request/system";
import { getAppBaseApi, getHeaders } from "@/api/request";

//组件
@Component({
  name: "SystemRanking",
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private listLoading: boolean = false; // 列表加载

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getRankList();

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      id: row.id, // ID
      title: row.title,
      value: row.value,
      names: row.names,
    };
    this.picUrl = row.value;
    this.picFileList = [{ name: "name.jpg", url: row.value }];

    //显示界面
    this.dialogVisible = true;
  }

  //----------------------------- 编辑 -----------------------------
  //定义变量
  private picUrl: any = null;
  private picFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //表单数据
  private form: any = {
    //数据
    id: 2, // ID
    title: "",
    value: "", // 图标路径
    names: {
      zh: "", // 中文
      en: "", // 英文
      id: "", // 印尼语
    },
  };

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //保存按钮
  private async handlePush() {
    //数据处理
    if (!this.picUrl) return this.$message.error("请上传图片");
    if (this.form.names.zh == "") return this.$message.error("请输入中文名称");
    if (this.form.names.en == "") return this.$message.error("请输入英文名称");
    if (this.form.names.id == "") return this.$message.error("请输入印尼语名称");

    //显示等待
    this.dialogLoading = true;

    //数据赋值i
    this.form.value = this.picUrl;

    //保存任务
    await setRank(this.form);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //图片动画预览
  private handlePicPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //上传文件
  private handlePicBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 10;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出10M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handlePicRemove(file: any, fileList: any): void {
    this.picUrl = null;
    this.picFileList = [];
  }

  //上传图片成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picFileList = fileList;
    this.picUrl = response.data.images[0];
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}
